import { Component, OnInit } from '@angular/core'
import { FillService } from '../fill.service'
import { ActivatedRoute} from '@angular/router'
import _ from 'lodash'

@Component({
  selector: 'app-behavior',
  templateUrl: './behavior.component.html',
  styleUrls: ['./behavior.component.scss']
})
export class BehaviorComponent implements OnInit {
  fillKey: String;
  behavior: any[]
  normalIdealista = 'alto'
  normalExecutor = 'alto'
  normalSociocomunicativo = 'alto'
  normalMetodico = 'alto'
  estresseIdealista = 'alto'
  estresseExecutor = 'alto'
  estresseSociocomunicativo = 'alto'
  estresseMetodico = 'alto'

  // eslint-disable-next-line no-useless-constructor
  constructor (private fillService: FillService, private route: ActivatedRoute) { }

  ngOnInit () {
    this.route.paramMap.subscribe(params => {
      this.fillKey = params.get('key')
      this.getResults()
    })
  }

  getResults () {
    this.fillService.getResult(this.fillKey).subscribe((data: any) => {
      this.behavior = data
      this.calculateResults()
    })
  }

  calculateResults () {
    _.forEach(this.behavior, q => {
      switch (q.Profile) {
        case 'idealista':
          q.p = 'Idealista'
          break
        case 'executor':
          q.p = 'Executor'
          break
        case 'metodico':
          q.p = 'Metodico'
          break
        case 'sociocomunicativo':
          q.p = 'Sociocomunicativo'
          break
      }
      if (q.Total >= 41) {
        this[q.situation + q.p] = 'muitoAlto'
      } else if (q.Total >= 35) {
        this[q.situation + q.p] = 'alto'
      } else if (q.Total >= 23) {
        this[q.situation + q.p] = 'moderado'
      } else {
        this[q.situation + q.p] = 'baixo'
      }
      console.log(q.situation + q.p, q.Total, this[q.situation + q.p])
    })
  }
}
