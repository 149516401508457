import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-choose-fill',
  templateUrl: './choose-fill.component.html',
  styleUrls: ['./choose-fill.component.scss']
})
export class ChooseFillComponent implements OnInit {
  fillKey: String;

  constructor (private router: Router) { }

  ngOnInit () {}

  gotoQuiz () {
    // console.log(this.fillKey)
    this.router.navigate([`/fill/${this.fillKey}`])
  }
}
