import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { throwError } from 'rxjs'
import { retry, catchError } from 'rxjs/operators'
import { Fill } from './models/fill'
import { environment } from '../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class FillService {
  constructor (private http: HttpClient) { }

  get (key:String) {
    return this.http.get<Fill>(`${environment.apiURL}/fills/${key}`)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  getResult (key:String) {
    return this.http.get<Fill>(`${environment.apiURL}/fills/${key}/results`)
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  handleError (error: HttpErrorResponse) {
    let errorMessage = ''
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message
    } else {
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`
    }
    console.log(errorMessage)
    return throwError(errorMessage)
  };
}
